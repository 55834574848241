.arrowdown {
    display: none;
    font-size: 2.5rem;
    z-index: 3;
    position: absolute;
    bottom: 4rem;
    opacity: 1;
    transition: ease 0.1s;

    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

}

.arrowdown.active {
    opacity: 0;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
