.slick-dots {
    bottom: 5rem;
    z-index: 10;
  }
  .slick-dots li button::before {
    color: #ccc;
  }
  .slick-dots li.slick-active button::before {
    color: #fff;
    opacity: 1;
  }