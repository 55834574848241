.navbar {
    background: transparent;
}

.navbar.active {
    background: #161616;
}

.icon {
    margin: .1rem 0 0 1rem;
    height: 1.3rem;
    color: white;
    cursor: pointer;
}

#fugly {
    color: #ff3399;
    font-weight: 510;
}